import { deepEqual } from '@/components/utils/common'
import scroll from '@/components/directives/scroll'
import scrollMix from '@/components/mixins/scroll'
import _ from 'lodash'

/*todo: пересмотреть код который касается чекбоксов в таблице*/
export default {
  directives: {
    scroll
  },
  mixins: [scrollMix],
  props: {
    value: {
      requestParams: {
        options: {
          type: Object,
          default: {}
        },
        filters: {
          type: Object,
          default: {}
        }
      },
      model: {
        type: Object,
        default: null
      },
      state: {
        type: Object,
        default: {}
      },
      client_id: {
        type: Number,
        default: null
      },
      agent_id: {
        type: Number,
        default: null
      },
      id: {
        type: Number,
        default: null
      }
    }
  },
  data () {
    return {
      loadingTable: false,
      items: [],
      itemsLength: 0,
      loadingAutocomplete: false,
      search: null,
      localRequestParams: this.value.requestParams,
      localModel: this.value.model,
      localState: this.value.state,
      localCompilationId: this.id,
      localAgentId: this.agent_id,
      localClientId: this.client_id,
      localTitle: this.title
    }
  },
  watch: {
    localRequestParams: {
      handler: async function () {
        this.$emit('change', {
          requestParams: this.localRequestParams
        })
        if (this.value.requestParams) {
          this.debounceFillTable()
          /*note: нашел кусок из-за которого все переписывалось, возможно
          для чего-то нужен, но я не нашел для чего, поэтому пока оставил так.*/
          /*this.localState.selectedState = {
            excludedItems: [],
            selectedItems: [],
            selectAll: true
          }*/
        }
        return true
      },
      deep: true
    },
    localState: {
      handler () {
        this.$emit('change', {
          state: this.localState
        })
      },
      deep: true
    },
    localModel: {
      handler () {
        this.$emit('change', {
          model: this.localModel
        })
      },
      deep: true
    }
  },
  computed: {
    canSaveCompilation () {
      return !!this.value.client_id
    }
  },
  methods: {
    debounceFillTable: _.debounce(async function () {
      let res = await this.fillTable()
      setTimeout(this.scrollToSaved, 200)
      return res
    }, 1000),
    async fillTable () {
      this.loadingTable = true
      this.items = []
      const response = await this.$store.dispatch('table/fillTable', {
        url: this.fillTableUrl,
        rawData: {
          options: this.value.requestParams.options,
          filters: this.value.requestParams.filters
        }
      })
      if (response) {
        this.items = response.models
        this.itemsLength = response.count
      }
      this.loadingTable = false
      return true
    },
    async saveCompilation (type) {
      if (!this.isSavingData) {
        this.isSavingData = true
        const response = await this.$store.dispatch('detail/save', {
          url: '/compilation/detail',
          data: {
            id: this.value.id,
            agent_id: this.value.agent_id,
            client_id: this.value.client_id,
            name: this.value.title,
            compilation: this.value.requestParams.filters,
            type: type
          }
        })
        if (response) {
          this.value.id = response.id
        }
        this.isSavingData = false
      }
      return true
    },
    handleControls (data) {
      if (!deepEqual(this.localRequestParams.filters, data)) {
        this.localRequestParams.options.page = 1
      }
      this.localRequestParams.filters = data
    },
    handleScrollPosition: _.debounce(function () {
      if (!this.loadingTable) {
        this.setScrollPosition()
      }
    }, 200)
  },
  created () {
    if (!this.localState) {
      this.$set(this, 'localState', {
        openedItems: [],
        selectedState: {
          excludedItems: [],
          selectedItems: [],
          selectAll: false
        },
        scrollPosition: 0
      })
    }
  }
}
