<template>
  <v-container fluid>
    <v-row class="text-left">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :options="options"
          @update:options="$emit('input', $event)"
          :server-items-length="itemsLength"
          :loading="loading"
          class="elevation-1"
          multi-sort
          @click:row="detail($event.id)"
        >
          <template v-slot:item.userPhones="{ item }">
            <div
              v-for="phone in item.userPhones"
              :key="phone.phone.phone"
            >
              {{phone.phone.phone}}
            </div>
          </template>
<!--          <template v-slot:item.role="{ item }">-->
<!--            <div>{{formatRoleName(item.role)}}</div>-->
<!--          </template>-->
<!--          <template v-slot:item.parent_id="{ item }">-->
<!--            <div>{{formatValue('users.agents', item.parent_id)}}</div>-->
<!--          </template>-->
          <template v-slot:item.is_online="{ item }">
            <div>{{processOnline(item)}}</div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import headers from './header'
import formatters from '@/components/mixins/formatters'
import table from '@/components/mixins/table'

export default {
  mixins: [formatters, table],
  name: 'Table',
  data: () => ({
    headers: headers
  }),
  methods: {
    processOnline (item) {
      let result = ''
      if (item.is_online) {
        result = 'Онлайн'
      } else {
        result = item.last_online ? 'Был ' + this.formatTodayDate(item.last_online) : ''
      }
      return result
    },
    formatRoleName (name) {
      const role = this.$store.getters['serviceData/roleByName'](name)
      return role ? role.description : ''
    }
  }
}
</script>

<style scoped>

</style>
