export default [
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '60px'
  },
  {
    text: 'Имя',
    align: 'left',
    sortable: true,
    value: 'username',
    width: '250px'
  },
  {
    text: 'Почта',
    align: 'left',
    sortable: false,
    value: 'email',
    width: '200px'
  },
  {
    text: 'Телефоны',
    align: 'left',
    sortable: false,
    value: 'userPhones',
    width: '120px'
  },
  // {
  //   text: 'Роль',
  //   align: 'left',
  //   sortable: true,
  //   value: 'role',
  //   width: '120px'
  // },
  // {
  //   text: 'Агент',
  //   align: 'left',
  //   sortable: true,
  //   value: 'parent_id',
  //   width: '150px'
  // },
  {
    text: 'Онлайн',
    align: 'left',
    sortable: true,
    value: 'is_online',
    width: '200px'
  }
]
