<template>
  <v-container
    fluid
    class="pt-3 pb-0"
  >
    <v-row class="text-left">
      <v-col
        cols="8"
        class="text-left pt-0 pb-0"
      >
        <v-select
          :value="filters.userType"
          @input="localFilters.userType = $event"
          :items="userRoleItemsWithEmpty"
          label="Выберите тип пользователя"
          chips
          dense
          small-chips
          elevation="1"
          class="text-left"
          item-value="name"
          item-text="description"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import controls from '@/components/mixins/controls/controls'
import userComputed from '@/components/mixins/userComputed'

export default {
  mixins: [controls, userComputed],
  name: 'Controls',
  components: { Autocomplete },
  data: () => ({
    localFilters: {
      userType: null
    }
  })
}
</script>

<style scoped>

</style>
