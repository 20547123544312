import * as Config from '@/config'

export default {
  methods: {
    formatValue (categoryChain, valueId) {
      return this.$store.getters['serviceData/optionValue'](categoryChain, valueId)
    },
    timestampToDatetime (timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleString('ru-RU')
    },
    formatTodayDate (timestamp) { // rename method format
      let rez = new Date(timestamp * 1000).toLocaleString('ru-RU')
      const createAt = new Date(timestamp * 1000).toLocaleDateString('ru-RU')
      if (createAt === new Date().toLocaleDateString('ru-RU')) {
        rez = 'Сегодня в ' + new Date(timestamp * 1000).toLocaleTimeString('ru-RU')
      }
      return rez
    },
    timestampToDate (timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleDateString('ru-RU')
    },
    dateToTimestamp (date) {
      return Date.parse(date) / 1000
    },
    maskValue (value, pattern) {
      if (value) {
        let i = 1
        let v = value.toString()
        return pattern.replace(/#/g, _ => v[i++])
      }
    },
    cutString (str, maxSymbols) {
      let localStr = str
      if (str && localStr.length > maxSymbols) {
        localStr = localStr.substr(0, maxSymbols)
      }
      return localStr
    },
    advertClassByStatus (advert) {
      let resultClass = ''
      if (advert.source === Config.advertSources.public) {
        resultClass = 'adv-public'
      }
      if (advert.adv_status === Config.advertStatuses.deleted) {
        resultClass = 'adv-deleted'
      }
      if (advert.adv_status === Config.advertStatuses.active) {
        resultClass = 'adv-active'
      }
      if ((advert.adv_status === Config.advertStatuses.active || advert.adv_status === null) && advert.delay_until) {
        resultClass = 'delay-until'
      }
      if (advert.adv_status === Config.advertStatuses.sold) {
        resultClass = 'adv-sold'
      }
      if (advert.adv_status === Config.advertStatuses.scrappedBefore) {
        resultClass = 'statusScrappedBefore'
      }
      return resultClass
    },
    phoneCallClass (status) {
      let resultClass = ''
      switch (status) {
        case Config.phoneCallStatuses.available:
          resultClass = 'statusGreen'
          break
        case Config.phoneCallStatuses.unavailable:
          resultClass = 'statusRed'
          break
        case Config.phoneCallStatuses.notAnswer:
          resultClass = 'statusYellow'
          break
        case Config.phoneCallStatuses.clear:
          resultClass = 'statusBlue'
          break
        case Config.phoneCallStatuses.busy:
          resultClass = 'statusGold'
      }
      return resultClass
    },
    phoneStatusClass (status) {
      let resultClass = ''
      switch (status) {
        case Config.phoneStatuses.existed:
          resultClass = 'statusGreen'
          break
        case Config.phoneStatuses.substitute:
          resultClass = 'statusRed'
          break
        case Config.phoneStatuses.notCalled:
          resultClass = 'statusYellow'
      }
      return resultClass
    },
    formatPrice (price) {
      let formatter = new Intl.NumberFormat('ru')
      return formatter.format(price)
    },
    reformatPrice (price) {
      return parseInt(price.replace(/\xA0/g, ''))
    },
    bytesToSize (bytes, seperator = '') {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes}${seperator}${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
    }
  }
}
