//todo: сделать глобальный рефакторинг и привести Controls к одному виду

export default {
  props: {
    filters: Object,
    loading: Boolean
  },
  data () {
    return {
      isPhoneNumberChanging: false,
      localFilters: { ...this.filters },
      forbiddenAttributes: ['phoneFilter', 'advertId', 'isVerified', 'advStatus', 'strategy', 'clientStatus', 'clientCompilationId'] // эти атрибуты не активируют фильтр
    }
  },
  watch: {
    'localFilters': {
      handler () {
        this.$emit('input', { ...this.localFilters })
      },
      deep: true
    }
  },
  methods: {
    handleInputFilter (data) {
      delete data.advertId
      delete data.phoneFilter
      this.localFilters = { ...data }
    },
    handleIsVerifiedFilter (data) {
      this.localFilters = { ...this.localFilters, ...data.value }
    },
    handleClientCategoryFilter (data) {
      this.localFilters = { ...this.localFilters, ...{ clientCategory: data } }
    },
    handleClientIdFilter (data) {
      this.localFilters = { ...this.localFilters, ...{ clientCompilationId: data } }
    },
    handlePhoneFilter (data) {
      if (data) {
        if (this.isFilterActive()) {
          this.localFilters.phoneFilter = ''
          this.$emit('list', { requestParams: { filters: { phoneFilter: data, isVerified: null } } })
        } else {
          this.localFilters = { ...this.localFilters, ...{ advertId: '', phoneFilter: data } }
        }
      } else {
        this.localFilters = { ...this.localFilters, ...{ phoneFilter: data } }
      }
    },
    isFilterActive () {
      let isActive = false
      for (let filterName in this.localFilters) {
        if (this.localFilters.hasOwnProperty(filterName) && !this.forbiddenAttributes.includes(filterName)) {
          if (Array.isArray(this.localFilters[filterName])) {
            if (this.localFilters[filterName].find(item => item !== null)) {
              isActive = true
              break
            }
          } else {
            if (this.localFilters[filterName]) {
              if (typeof this.localFilters[filterName] === 'object') {
                if (Object.keys(this.localFilters[filterName]).length) {
                  isActive = true
                  break
                }
              } else {
                isActive = true
                break
              }
            }
          }
        }
      }
      return isActive
    },
    handleChangeNumber (data) {
      let res = false
      if (this.localFilters.phoneFilter) {
        res = data === this.localFilters.phoneFilter.phone
      }
      this.isPhoneNumberChanging = res
    }
  }
}
