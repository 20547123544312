export default {
  computed: {
    userRoleItems () {
      return this.$store.getters['serviceData/roles']
    },
    userRoleItemsWithEmpty () {
      const items = this.$store.getters['serviceData/roles']
      items.unshift({ name: null, description: 'Все' })
      return items
    },
    customPermissionsItems () {
      return this.$store.getters['serviceData/customPermissions']
    }
  }
}
