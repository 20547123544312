<template>
  <v-autocomplete
    v-if="mask"
    :value="value"
    @input="model = $event"
    :items="getItems"
    :item-value="itemValue"
    :item-text="itemText"
    :loading="loading"
    :search-input.sync="search"
    :label="placeholder"
    :cache-items="false"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    return-object
    v-mask="mask"
    :outlined="outlined"
    :dense="dense"
  >
    <slot
      name="appendItem"
    ></slot>
  </v-autocomplete>
  <v-autocomplete
    v-else
    :value="value"
    @input="model = $event"
    :items="getItems"
    :item-value="itemValue"
    :item-text="itemText"
    :loading="loading"
    :search-input.sync="search"
    :cache-items="false"
    :label="placeholder"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    return-object
    :outlined="outlined"
    :dense="dense"
    :hide-details="hideDetails"
  >
  </v-autocomplete>
</template>

<script>
/*//todo: найти способ убрать костыль с маской (установил новый плагин после теста удалить старый)
import { mask } from '@titou10/v-mask'*/
import { maskValue } from '@/components/utils/common'
import _ from 'lodash'

export default {
  name: 'Autocomplete',
  /*directives: { mask },*/
  props: {
    value: {},
    mask: {
      default: false
    },
    hideDetails: Boolean,
    dense: Boolean,
    outlined: Boolean,
    placeholder: String,
    appendIcon: String,
    prependIcon: String,
    itemText: String,
    itemValue: String,
    url: String,
    charsToSearch: {
      type: Number,
      default: 5
    }
  },
  data: () => ({
    items: [],
    loading: false,
    search: null,
    model: '',
    autocompleteString: ''
  }),
  watch: {
    search: {
      async handler () {
        const clearSearch = this.search && this.mask ? this.search.split(' ').join('') : this.search
        if (clearSearch === '' || clearSearch === null) {
          this.model = ''
          this.items = []
          this.autocompleteString = ''
        } else if (!this.loading && clearSearch.length >= this.charsToSearch && (!this.model || this.search !== this.model[this.itemText])) {
          this.autocompleteString = clearSearch
        } else {
          this.autocompleteString = ''
        }

        await this.debounceAutocomplete()
        this.$emit('changeNumber', this.search)
        return true
      }
    },
    model: {
      handler () {
        this.$emit('input', this.model)
        this.$emit('changeNumber', this.search)
      },
      deep: true
    }
  },
  computed: {
    getItems () {
      return this.items
    }
  },
  methods: {
    //note: используем debounce для задержки ввода
    debounceAutocomplete: _.debounce(async function () {
      return this.autocomplete()
    }, 400),
    async autocomplete () {
      if (this.autocompleteString) {
        this.loading = true
        const response = await this.$store.dispatch('table/autocomplete', {
          url: this.url,
          queryString: this.autocompleteString
        })
        if (response && response.length) {
          this.items = this.processItems(response)
        }
        this.loading = false
      }
      return true
    },
    processItems (items) {
      if (this.mask) {
        items.forEach(item => {
          item.phone = maskValue(item.phone, this.mask)
        })
      }
      return items
    }
  },
  created () {
    if (this.value) {
      this.items.push(this.value)
      this.model = this.value
      this.search = this.model[this.itemText]
    }
  }
}
</script>

<style scoped>

</style>
