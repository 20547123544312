export default {
  methods: {
    scrollToSaved () {
      window.scrollTo(0, this.getScrollPosition())
    },
    setScrollPosition () {
      this.localState.scrollPosition = window.scrollY
    },
    getScrollPosition () {
      let position = 0
      if (this.localState && this.localState.scrollPosition) {
        position = this.localState.scrollPosition
      }
      return position
    }
  }
}
